/**/
const DAYS_IN_YEAR = 366;
export const MONTH_LIST = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

/**/
export const DAY_ARRAY = Array(DAYS_IN_YEAR)
  .fill(null)
  .map((_, idx) => ({ day: idx + 1 }));

/**/
export const DEFAULT_PROPS = {
  CURVE_CHART: {
    margin: {
      right: 4,
      left: 4,
      top: 1
    }
  },
  DATE_PICKER: {
    allowClear: false,
    className: 'HCPeriodSelect__picker',
    format: 'DD MMM',
    getPopupContainer: () => document.querySelector('.Main__content'),
    rootClassName: 'HCPeriodSelect__calendar',
    superNextIcon: null,
    superPrevIcon: null
  },
  ZONE_CHART: {
    margin: {
      right: 4,
      left: 4,
      top: -1
    }
  },
  REF_AREA: {
    y1: 1,
    y2: 7e4,
    fill: 'red',
    fillOpacity: 0.1,
    ifOverflow: 'extendDomain',
    stroke: 'red',
    strokeDasharray: '4 4',
    strokeOpacity: 0.3
  },
  XAXIS: {
    height: 2,
    stroke: '#e7e7e7',
    tickLine: false
  },
  YAXIS: {
    axisLine: false,
    scale: 'log',
    domain: ['auto', 'auto'],
    width: 0
  }
} as any;
