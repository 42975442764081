import React from 'react';
import message from 'antd/lib/message';

import { IconCustom } from '../IconCustom/IconCustom';

/**
 *
 */
export class HeavyLoader {
  private static WARNING_KEY = 'WARNING_KEY';

  /**/
  public static show(): void {
    message.open({
      duration: 0,
      key: HeavyLoader.WARNING_KEY,
      content: (
        <>
          <IconCustom className="HistoricalTrack__spinner" type="loading" />
          <span>Loading heavy data...</span>
        </>
      )
    });
  }

  /**/
  public static hide(): void {
    message.destroy(HeavyLoader.WARNING_KEY);
  }
}
