import React from 'react';

import 'pages/Map/controls/LayerFilter/LayerFilter.scss';
import {
  EquipmentSelector,
  SpecieSelector,
  VesselSelector,
  WeightSelector
} from './ImplementedHCSelectors';
import { WEIGHT_RANGES } from '../helpers';

import { SelectedOptions, TCatchFilterOptions } from '../types';

/**/
type Props = {
  onChanges: (options: Partial<SelectedOptions>) => void;
  options: TCatchFilterOptions;
  values: SelectedOptions;
};

type State = {};

/**
 *
 */
class HistoricalCatchControls extends React.PureComponent<Props, State> {
  /**/
  handleEq = (equipment: number[]) => this.props.onChanges({ equipment });
  /**/
  handleSp = (species: number[]) => this.props.onChanges({ species });
  /**/
  handleVessels = (vessels: number[]) => this.props.onChanges({ vessels });
  /**/
  handleWeight = (weights: number[]) => this.props.onChanges({ weights });

  /**/
  render() {
    const { options, values } = this.props;

    return (
      <div className="LayerFilter">
        <div className="LayerFilter__container">
          <div className="LayerFilter__content HistoricalCatchControls__content">
            <VesselSelector
              onChange={this.handleVessels}
              options={options.vessels}
              value={values.vessels}
            />

            <SpecieSelector
              onChange={this.handleSp}
              options={options.species}
              value={values.species}
            />

            <WeightSelector
              onChange={this.handleWeight}
              options={WEIGHT_RANGES}
              value={values.weights}
            />

            <EquipmentSelector
              onChange={this.handleEq}
              options={options.equipment}
              value={values.equipment}
            />
          </div>
        </div>
      </div>
    );
  }
}

/**/
export default HistoricalCatchControls;
