import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './HistoricalCatchPage.scss';
import { fetchVesselsAction } from 'store/vessel/vessels/vesselsActions';
import HistoricalCatch from './HistoricalCatch';
import {
  isMobile,
  MultiSelect,
  ReportPagePlaceholder,
  ReportPageSizeWarning
} from 'components';
import { withPlaceholderPage } from 'components/withPlaceholderPage';

import { EUserAuthority, TSelectOption, TVessel } from 'types';
import { TCatchFilterOptions } from './types';
import { TState } from 'store';

/**/
type Props = {
  onInit: () => void;
  options: TCatchFilterOptions;
};

type State = {};

/**
 *
 */
class Component extends React.PureComponent<Props, State> {
  /**/
  componentDidMount() {
    this.props.onInit();
  }

  /**/
  render() {
    return (
      <div className="HistoricalCatch">
        <HistoricalCatch options={this.props.options} />
      </div>
    );
  }
}

/**
 *
 */
function HistoricalCatchPage() {
  const dispatch = useDispatch();

  const { dictionaries, isLoading, vessels } = useSelector((state: TState) => ({
    dictionaries: state.dictionaries,
    isLoading: state.vessels.isPending,
    vessels: state.vessels.vessels
  }));

  const _vessels = vessels.map(
    ({ id, name }: TVessel): TSelectOption<number> => ({
      label: name,
      value: id
    })
  );

  const props = {
    onInit: () => {
      if (vessels.length > 0 || isLoading) return;
      dispatch(fetchVesselsAction());
    },
    options: {
      equipment: [
        MultiSelect.getAllOption('All equipment'),
        ...dictionaries.vesselGearTypes
      ],
      species: [
        MultiSelect.getAllOption('All species'),
        ...dictionaries.species
      ],
      vessels: [MultiSelect.getAllOption('All vessels'), ..._vessels]
    }
  };

  return <Component {...props} />;
}

/**/
export default withPlaceholderPage<Props>(
  isMobile() ? ReportPageSizeWarning : HistoricalCatchPage,
  [EUserAuthority.HISTORICAL_CATCH],
  ReportPagePlaceholder
);
