import { MultiSelect } from 'components';
import { TSelectOption } from 'types';

/**/
export const WEIGHT_RANGES: TSelectOption<number>[] = [
  MultiSelect.getAllOption('All weight ranges'),
  {
    value: 1,
    label: '1-10'
  },
  {
    value: 2,
    label: '11-30'
  },
  {
    value: 3,
    label: '31-50'
  },
  {
    value: 4,
    label: '51-100'
  },
  {
    value: 5,
    label: '101-150'
  },
  {
    value: 6,
    label: '151-200'
  },
  {
    value: 7,
    label: '201-300'
  },
  {
    value: 8,
    label: '301 and higher'
  }
];

/**/
const MAX_WEIGHT = 700;
const MAX_WEIGHT_MAP = {
  1: 10,
  2: 30,
  3: 50,
  4: 100,
  5: 150,
  6: 200,
  7: 300,
  8: MAX_WEIGHT
};

/**
 *
 */
function getMaxWeight(weights: number[]): number {
  return null;
}

/**/
const YEAR_LIST_LENGTH = 4;
const currentYear = new Date().getFullYear();
export const YEAR_LIST = Array(YEAR_LIST_LENGTH)
  .fill(null)
  .map((_, idx) => currentYear - idx);
