import React, { lazy } from 'react';
import isequal from 'lodash.isequal';

import {
  CatchHistoryRecord,
  fetchCatchHistory,
  fetchCatchPoints
} from './HistoricalCatchControls/helpers';
import HCSwipeMenu from './HistoricalCatchControls/HCSwipeMenu';
import { HeavyLoader } from 'components/common/playerAndMap/other';
import HistoricalCatchControls from './HistoricalCatchControls/HistoricalCatchControls';
import { WEIGHT_RANGES } from './helpers';
import { withMap } from 'components';

import {
  PeriodSettings,
  SelectedOptions,
  TCatchFilterOptions,
  THistoricalCatchPoint
} from './types';
import { TSelectOption } from 'types';

/**/
type Props = {
  options: TCatchFilterOptions;
};

type State = SelectedOptions &
  PeriodSettings & {
    catchHistory: CatchHistoryRecord[];
    catchPoints: THistoricalCatchPoint[];
  };

/**/
const Map = withMap(
  lazy(() => import('./HistoricalCatchMap/HistoricalCatchMap'))
) as any;

/**
 *
 */
class HistoricalCatch extends React.PureComponent<Props, State> {
  state: State = {
    catchHistory: [],
    catchPoints: [],
    equipment: [],
    end: 165,
    species: [],
    start: 100,
    vessels: [],
    weights: WEIGHT_RANGES.slice(2).map((w: TSelectOption<number>) => w.value),
    years: [2022] // YEAR_LIST.slice(0, 3)
  };

  /**/
  componentDidMount() {
    this.fetchData();
  }

  /**/
  componentDidUpdate(_, prevState: Readonly<State>) {
    const { catchHistory: x, catchPoints: y, ...state } = this.state;
    const { catchHistory, catchPoints, ...prev } = prevState;
    isequal(prev, state) || this.fetchData();
  }

  /**/
  fetchData() {
    const { catchHistory, catchPoints, ...options } = this.state;
    this.setState({ catchHistory: [], catchPoints: [] });
    HeavyLoader.show();

    fetchCatchHistory(options, (catchHistory: CatchHistoryRecord[]) => {
      HeavyLoader.hide();
      this.setState({ catchHistory });
    });
    fetchCatchPoints(options, (catchPoints: THistoricalCatchPoint[]) => {
      HeavyLoader.hide();
      this.setState({ catchPoints });
    });
  }

  /**/
  handleOptions = (options: Partial<PeriodSettings | SelectedOptions>) =>
    this.setState({
      ...this.state,
      ...options
    });

  /**/
  render() {
    const { catchHistory, catchPoints, weights } = this.state;

    return (
      <div className="HistoricalCatch__content">
        <Map catchPoints={catchPoints} weights={weights} />

        <HistoricalCatchControls
          onChanges={this.handleOptions}
          options={this.props.options}
          values={this.state}
        />

        <HCSwipeMenu
          catchHistory={catchHistory}
          onChange={this.handleOptions}
          values={this.state}
        />
      </div>
    );
  }
}

/**/
export default HistoricalCatch;
