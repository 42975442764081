import commonFF from 'styles/cssVariables/commonFF.json';
import { FF_PRODUCT_ROUTES, ROUTES } from './constants';
import { ffVariables } from '../styles/cssVariables/ff';
import { ROUTE_ITEMS } from './routeItems';

export const CONFIG = {
  ANALYTICS_APP_URL: 'https://analytics.fishfacts.com/',
  APP_ABBR: 'FF',
  APP_NAME: 'FishFacts',
  // Misc
  CONSOLE_COLOR: '#96ceb4',
  FB_APP_ID: '181656619777466',
  FLEET: 'fleet',
  FLEETS: 'fleets',
  VESSEL_NAME_ZOOM_THRESHOLD: 5,
  // Storage/Settings
  STORE_SETTINGS_KEY: 'FF_COM',
  STORE_AUTH_KEY: 'FF_COM_AUTH',
  MAIN_FILTER: 'FF_MAIN_FILTER',
  // Html element
  HEADER_LOGO: '/assets/logo/LogoFF.svg',
  INTRO_LOGO: '/assets/logo/Logo-2FF.svg',
  IS_FF: true,
  REPORT: {
    ANALYTICS: {
      PROD: 'https://tableau.fishfacts.com/trusted/%%TOKEN%%/views/FishFactsAnalytics-DrafttoDmytrov2/OperationalMarketOverview',
      TEST: 'https://tableau.fishfacts.com/trusted/%%TOKEN%%/views/FishFacts_Analytics_Staging/OperationalMarketOverview'
    }
  },
  ROUTES: {
    _ABOUT: '/service/provider/26'
  },
  // Styles
  CSS_VARS: commonFF,
  THEME: ffVariables,
  // LandingPage nav links
  LANDING_PAGE_NAV_CATEGORIES: [
    {
      imageSrc: '/assets/home/MapFF.png',
      path: FF_PRODUCT_ROUTES.MAP,
      title: 'Map'
    },
    {
      imageSrc: '/assets/home/VesselsFF.png',
      path: FF_PRODUCT_ROUTES.VESSELS,
      title: 'Vessels'
    },
    {
      imageSrc: '/assets/home/NewsFF.png',
      path: FF_PRODUCT_ROUTES.NEWS,
      title: 'News'
    },
    {
      imageSrc: '/assets/home/AnalyticsFF.png',
      path: FF_PRODUCT_ROUTES.ANALYTICS,
      title: 'Analytics'
    },
    {
      imageSrc: '/assets/home/ServicesFF.png',
      path: FF_PRODUCT_ROUTES.SERVICES,
      title: 'Service Providers'
    },
    {
      imageSrc: '/assets/home/EventsFF.png',
      path: ROUTES.EVENTS,
      title: 'Events'
    },
    {
      imageSrc: '/assets/home/CRMFF.png',
      path: FF_PRODUCT_ROUTES.CRM,
      title: 'CRM'
    },
    {
      imageSrc: '/assets/home/HistoricalTrackFF.png',
      path: FF_PRODUCT_ROUTES.HISTORICAL_TRACK,
      title: 'Historical Track'
    },
    {
      imageSrc: '/assets/home/ActivityProfile.png',
      path: FF_PRODUCT_ROUTES.ACTIVITY_PROFILE,
      title: 'Activity Profile'
    }
  ],
  // Nav menu links
  NAVIGATION: [
    ROUTE_ITEMS.HOME,
    ROUTE_ITEMS.MAP,
    ROUTE_ITEMS.VESSELS,
    {
      title: 'Analysis',
      key: 'analysis',
      icon: 'bar-chart',
      items: [
        ROUTE_ITEMS.ACTIVITY_PROFILE,
        ROUTE_ITEMS.ANALYTICS,
        ROUTE_ITEMS.HISTORICAL_TRACK,
        ROUTE_ITEMS.HISTORICAL_CATCH
      ]
    },
    {
      title: 'Service Providers',
      key: 'services',
      icon: 'table',
      items: [ROUTE_ITEMS.SERVICES, ROUTE_ITEMS.CRM, ROUTE_ITEMS.EVENTS]
    },
    ROUTE_ITEMS.CUSTOM_AKER_BIOMARINE,
    ROUTE_ITEMS.CUSTOM_NORDIC_WILDFISH,
    ROUTE_ITEMS.CUSTOM_VROLIJK
  ],
  NAVIGATION_ANON: [
    ROUTE_ITEMS.HOME,
    {
      path: FF_PRODUCT_ROUTES.MAP,
      title: 'Map',
      icon: 'map'
    },
    {
      path: FF_PRODUCT_ROUTES.VESSELS,
      title: 'Vessels',
      icon: 'vessel'
    },
    {
      title: 'Analysis',
      key: 'analysis',
      icon: 'bar-chart',
      items: [
        {
          path: FF_PRODUCT_ROUTES.ACTIVITY_PROFILE,
          title: 'Activity'
        },
        {
          path: FF_PRODUCT_ROUTES.ANALYTICS,
          title: 'Finance'
        },
        {
          path: FF_PRODUCT_ROUTES.HISTORICAL_TRACK,
          title: 'Track'
        },
        {
          path: FF_PRODUCT_ROUTES.HISTORICAL_CATCH,
          title: 'Catch',
          extra: 'New'
        }
      ]
    },
    {
      title: 'Service Providers',
      key: 'services',
      icon: 'table',
      items: [
        ROUTE_ITEMS.SERVICES,
        {
          path: FF_PRODUCT_ROUTES.CRM,
          title: 'CRM'
        },
        ROUTE_ITEMS.EVENTS
      ]
    }
  ],
  MOBILE_APP: {
    APP_STORE: 'https://itunes.apple.com/us/app/fishfacts-fo/id1447608197?mt=8',
    GOOGLE_PLAY:
      'https://play.google.com/store/apps/details?id=fo.fishfacts.fishfactsapp'
  }
};
