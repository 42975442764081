import { ENDPOINTS } from 'other';
import { http, THttpResponse } from 'services';

import { PeriodSettings, SelectedOptions } from '../types';
import { THistoricalCatchPoint, THistoricalCatchRecord } from '../types';

/**/
const DEFAULT_COLOR = '#E0E0E0';
const COLOR_LIST = ['#94BFFF', '#3DDD9A', '#C39EFF', '#FFBE72'];

/**/
const ZERO = 1;
const WEEKS_IN_YEAR = 53;

/**/
let historyController;
let pointsController;

const PARAMS_MAP = {
  end: 'end',
  equipment: 'gearIds',
  species: 'specieIds',
  start: 'start',
  vessels: 'vesselIds',
  weights: 'weightRangeIds',
  years: 'years'
};

/**
 *
 */
export function getColourByYear(year: number) {
  const thisYear = new Date().getFullYear();
  return COLOR_LIST[thisYear - year] || DEFAULT_COLOR;
}

/**
 *
 */
export class CatchHistoryRecord {
  week: number;

  constructor(week: number, years: number[]) {
    this.week = week;
    years.forEach((y) => this.addValue(y));
  }

  addValue(year: number, value: number = ZERO) {
    this[String(year)] = value;
  }
}

/**
 *
 */
function mapCatchData(data, years: number[]): CatchHistoryRecord[] {
  const map = new Map();

  Array(WEEKS_IN_YEAR)
    .fill(null)
    .forEach((_, idx) =>
      map.set(idx + 1, new CatchHistoryRecord(idx + 1, years))
    );

  data.forEach(({ interval, weight, year }) =>
    map.get(interval.index).addValue(year, weight || ZERO)
  );

  return Array.from(map.values());
}

/**
 *
 */
export function fetchCatchHistory(
  options: SelectedOptions & PeriodSettings,
  onSuccess: (history: CatchHistoryRecord[]) => void
): void {
  const { years } = options;

  if (years.length === 0) return onSuccess([]);

  historyController?.abort();
  historyController = new AbortController();

  http
    .send({
      method: 'GET',
      signal: historyController.signal,
      url: ENDPOINTS.VESSEL_TRIP_HISTORY + buildQuery(options)
    })
    .then(({ data }: THttpResponse<THistoricalCatchRecord[]>) => {
      onSuccess(mapCatchData(data, options.years));
    })
    .catch(window.console.error);
}

/**
 *
 */
export function fetchCatchPoints(
  options: SelectedOptions & PeriodSettings,
  onSuccess: (history: THistoricalCatchPoint[]) => void
): void {
  const { years } = options;

  if (years.length === 0) return onSuccess([]);

  pointsController?.abort();
  pointsController = new AbortController();

  http
    .send({
      method: 'GET',
      signal: pointsController.signal,
      url: ENDPOINTS.VESSEL_TRIP + buildQuery(options)
    })
    .then(({ data }: THttpResponse<THistoricalCatchPoint[]>) => onSuccess(data))
    .catch(window.console.error);
}

/**
 *
 */
function buildQuery(options: SelectedOptions & PeriodSettings): string {
  let query = '';

  Object.entries(options).forEach(([key, val]: [string, number | number[]]) => {
    const k = PARAMS_MAP[key];
    if (!k || val === void 0 || (val as number[]).length === 0) return;

    if (Array.isArray(val)) {
      val.forEach((v: number) => (query += `&${k}=${v}`));
    } else {
      query += `&${k}=${val}`;
    }
  });

  return `?${query.slice(1)}`;
}
