import React from 'react';
import DatePicker from 'antd/lib/date-picker';
import dayjs, { Dayjs } from 'dayjs';

import './HCPeriodSelect.scss';
import { CatchHistoryRecord } from '../helpers';
import { DEFAULT_PROPS, MONTH_LIST } from './helpers';
import HCPlot from './HCPlot';
import HCYearList from './HCYearList';
import { IconButton } from 'components';
import { PeriodSettings } from '../../types';

/**/
type Props = {
  catchHistory: CatchHistoryRecord[];
  onChange: (settings: Partial<PeriodSettings>) => void;
  onClose: () => void;
  values: PeriodSettings;
};

/**
 *
 */
class HCPeriodSelect extends React.PureComponent<Props> {
  /**/
  handleStart = (start: Dayjs) =>
    this.props.onChange({
      start: dayjs(start).dayOfYear()
    });

  /**/
  handleEnd = (end: Dayjs) =>
    this.props.onChange({
      end: dayjs(end).dayOfYear()
    });

  /**/
  handleSlider = ([start, end]) => this.props.onChange({ start, end });

  /**/
  handleYear = (years: number[]) => this.props.onChange({ years });

  /**/
  render() {
    const { catchHistory, onClose, values } = this.props;
    const { end, start, years } = values;
    const _start = dayjs('2023').dayOfYear(start);
    const _end = dayjs('2023').dayOfYear(end);

    return (
      <div className="HCPeriodSelect">
        <header className="HCPeriodSelect__header">
          <div className="HCPeriodSelect__controls">
            <HCYearList onChange={this.handleYear} years={years} />

            <div className="HCPeriodSelect__range">
              <DatePicker
                placeholder="Start date"
                onChange={this.handleStart}
                value={_start}
                {...DEFAULT_PROPS.DATE_PICKER}
              />
              <DatePicker
                placeholder="End date"
                onChange={this.handleEnd}
                value={_end}
                {...DEFAULT_PROPS.DATE_PICKER}
              />
            </div>
          </div>

          <IconButton icon="minus" onClick={onClose} title="Collapse" />
        </header>

        <div className="HCPeriodSelect__content">
          <div className="HCPeriodSelect__plot">
            <HCPlot data={catchHistory} end={end} start={start} years={years} />
          </div>

          <ul className="HCPeriodSelect__months">
            {MONTH_LIST.map((mn) => (
              <li className="HCPeriodSelect__item" key={mn}>
                {mn}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

/**/
export default HCPeriodSelect;
